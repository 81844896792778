import { Column } from "components/Table/Simple";
import { formatDateTime } from "components/Table/Simple/presets";

export const columns: Column[] = [
    {
        field: "userId",
        label: "사용자ID",
        visible: true,
        creatable: true,
    },
    {
        field: "password",
        label: "비밀번호",
        visible: true,
        creatable: true,
    },
    {
        field: "name",
        label: "이름",
        visible: true,
        creatable: true,
        editable: true,
    },
    {
        field: "email",
        label: "이메일",
        visible: true,
        creatable: true,
        editable: true,
    },
    {
        field: "phoneNumber",
        label: "전화번호",
        visible: true,
        creatable: true,
        editable: true
    },
    {
        field: "address",
        label: "주소",
        visible: true,
        creatable: true,
        editable: true
    },
    {
        field: "status",
        label: "상태",
        visible: true,
        creatable: true,
        editable: true
    },
    {
        field: "createdAt",
        label: "생성일",
        visible: true,
        render: formatDateTime
    },
    {
        field: "updatedAt",
        label: "수정일",
        visible: true,
        render: formatDateTime
    }
]