import { Column } from "components/Table/Simple";
import { formatDateTime } from "components/Table/Simple/presets";

export const columns: Column[] = [
    {
        field: "staffId",
        label: "직원ID",
        visible: true,
        creatable: true,
    },
    {
        field: "password",
        label: "비밀번호",
        visible: true,
        creatable: true,
        editable: true,
    },
    {
        field: "name",
        label: "이름",
        visible: true,
        creatable: true,
        editable: true,
    },
    {
        field: "team",
        label: "팀",
        visible: true,
        creatable: true,
        editable: true,
    },
    {
        field: "note",
        label: "비고",
        visible: true,
        creatable: true,
        editable: true,
    },
    {
        field: "passwordResetRequired",
        label: "비밀번호 재설정 필요",
        render: (value) => value ? "○" : "✕",
        align: "center",
        visible: true,
        creatable: false,
        editable: true,
        selectable: {
            getOptions: () => [
                { value: true, name: "○" },
                { value: false, name: "✕" },
            ]
        }
    },
    {
        field: "createdAt",
        label: "생성일",
        visible: true,
        render: formatDateTime
    },
    {
        field: "updatedAt",
        label: "수정일",
        visible: true,
        render: formatDateTime
    }
]