
import React from "react";
import { Routes, Route } from "react-router-dom";

import StaffLists from "pages/Staff/Lists";

export default () => (
    <Routes>
        {/* <Route path="/editor" element={<ProductEditor />}>
            <Route path=":productId" element={<ProductLists />}></Route>
        </Route> */}
        <Route path="/lists" element={<StaffLists />}>
        </Route>
    </Routes>
)

