import { Column } from "components/Table/Simple";
import { formatDateTime } from "components/Table/Simple/presets";

export const columns: Column[] = [
    {
        field: "categoryId",
        label: "카테고리ID",
        visible: true,
    },
    {
        field: "name",
        label: "이름",
        visible: true,
        creatable: true,
        editable: true,
    },
    {
        field: "slug",
        label: "슬러그",
        visible: true,
        creatable: true,
        editable: true,
    },
    {
        field: "parentCategory",
        label: "상위 카테고리",
        visible: true,
        render: (value,field, row)=>{
            return row.parentCategory ? row.parentCategory.name : ""
        }
    },
    {
        field: "createdAt",
        label: "생성일",
        visible: true,
        render: formatDateTime
    },
    {
        field: "updatedAt",
        label: "수정일",
        visible: true,
        render: formatDateTime
    }
]