
import React from "react";
import { Routes, Route } from "react-router-dom";

import CategoryLists from "pages/Category/Lists";

export default () => (
    <Routes>
        <Route path="/lists" element={<CategoryLists />}></Route>
    </Routes>
)

