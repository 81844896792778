
import React from "react";
import { Routes, Route } from "react-router-dom";

import ShoppingCart from "containers/Store/Order/ShoppingCart";
import OrderSummaries from "containers/Store/Order/Summaries";
import OrderCheckout from "containers/Store/Order/Checkout";
import OrderHistory from "containers/Store/Order/History";
import OrderLists from "pages/Order/Lists"
export default () => (
    <Routes>
        <Route path="/shopping-cart" element={<ShoppingCart />}></Route>
        <Route path="/summaries" element={<OrderSummaries />}></Route>
        <Route path="/checkout" element={<OrderCheckout />}></Route>
        <Route path="/history" element={<OrderHistory />}></Route>
        <Route path="/lists" element={<OrderLists />}></Route>
    </Routes>
)

