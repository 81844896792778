import React, { useState } from "react";
import PagingTable from "components/Table/Paging";
import Card from "components/Card";
import PageTitle from "components/Title/Page";
import { voidPagingTableData } from "components/Table/Paging/helper";
import { columns } from "./config";
import axios from "axios";
import { toast } from "react-toastify";
import _ from "lodash";

const UserLists = () => {
    const [refreshKey, setRefreshKey] = useState(_.uniqueId());
    const fetchData = async () => {
        try {
            const ret = await axios.get(`https://rest.39f.io/users`);
            console.log("[fetchData] ret", ret);
            return {
                data: ret.data.data,
                page: 1,
                limit: 1
            }
        } catch (e) {
            console.error("[fetchData] error", e);
            return voidPagingTableData;
        }
    }

    const onCreate = async (data) => {
        console.log("[onCreate] callback", data);
        try {
            const ret = await axios.post(`https://rest.39f.io/users`, data);
            console.log("[onCreate] ret", ret);
            toast.success('user saved successfully');
            setRefreshKey(_.uniqueId());
        } catch (e) {
            console.error("[onCreate] error", e);
            toast.error('Failed to save user');
        }
        return true;
    }

    const onEdit = async (data) => {
        console.log("[onEdit] callback", data);
        try {
            const ret = await axios.put(`https://rest.39f.io/users/${data.userId}`, _.omit(data, ['userId']));
            console.log("[onEdit] ret", ret);
            toast.success('user updated successfully');
            setRefreshKey(_.uniqueId());
        } catch (e) {
            console.error("[onEdit] error", e);
            toast.error('Failed to update user');
        }
        return true;
    }

    const onDelete = async (data) => {
        console.log("[onDelete] callback", data);
        const ok = window.confirm('Are you sure to delete this user?');
        if (!ok) {
            return;
        }
        try {
            const ret = await axios.delete(`https://rest.39f.io/users/${data.userId}`);
            console.log("[onDelete] ret", ret);
            toast.success('user deleted successfully');
            setRefreshKey(_.uniqueId());
        } catch (e) {
            console.error("[onDelete] error", e);
            toast.error('Failed to delete user');
        }
        return true;
    }

    return (
        <Card>
            <div className="p-3">
                <PageTitle>회원목록</PageTitle>
                <PagingTable
                    refreshKey={refreshKey}
                    fetchData={fetchData}
                    columns={columns}
                    isCrudable={true}
                    onCreate={onCreate}
                    onEdit={onEdit}
                    onDelete={onDelete}
                />
            </div>
        </Card>
    )
}

export default UserLists;