import React from "react"
import SideNav from "components/SideNav"

const menus = [
    // {
    //     title: "Main",
    //     subMenus: [
    //         {
    //             title: "Home",
    //             to: "/main/home",
    //         },
    //     ],
    // },
    // {
    //     title: "Shop",
    //     subMenus: [
    //         {
    //             title: "Lists",
    //             to: "/shop/lists",
    //         },
    //         // {
    //         //     title: "Lists",
    //         //     to: "/product/lists",
    //         // },
    //         // {
    //         //     title: "Overviews",
    //         //     to: "/product/overviews",
    //         // },
    //         // {
    //         //     title: "Features",
    //         //     to: "/product/features",
    //         // },
    //     ],
    // },
    // {
    //     title: "Category",
    //     subMenus: [
    //         {
    //             title: "Lists",
    //             to: "/category/lists",
    //         },
    //         // {
    //         //     title: "Lists",
    //         //     to: "/category/lists",
    //         // },
    //     ],
    // },
    // {
    //     title: "Product",
    //     subMenus: [
    //         {
    //             title: "Lists",
    //             to: "/product/lists",
    //         },
    //         {
    //             title: "Editor",
    //             to: "/product/editor",
    //         },
    //         // {
    //         //     title: "Overviews",
    //         //     to: "/product/overviews",
    //         // },
    //         // {
    //         //     title: "Features",
    //         //     to: "/product/features",
    //         // },
    //     ],
    // },
    {
        title: "직원관리",
        subMenus: [
            {
                title: "직원목록",
                to: "/staff/lists",
            },
        ],
    },
    {
        title: "판매자관리",
        subMenus: [
            {
                title: "판매자목록",
                to: "/seller/lists",
            },
            // {
            //     title: "판매자리뷰",
            //     to: "/seller/review",
            // },
        ],
    },
    {
        title: "사용자관리",
        subMenus: [
            {
                title: "회원목록",
                to: "/user/lists",
            },
            // {
            //     title: "회원관리",
            //     to: "/user/editor",
            // },
        ],
    },
    {
        title: "상품관리",
        subMenus: [
            {
                title: "카테고리목록",
                to: "/product/category",
            },
            {
                title: "상품목록",
                to: "/product/lists",
            },
            {
                title: "상품검수",
                to: "/product/approval",
            },
        ],
    },
    {
        title: "주문관리",
        subMenus: [
            {
                title: "주문목록",
                to: "/order/lists",
            },
            {
                title: "반품관리",
                to: "/order/return",
            },
        ],
    },
    {
        title: "정산관리",
        subMenus: [
            {
                title: "정산현황",
                to: "/settlement/status",
            },
            {
                title: "정산이력",
                to: "/settlement/history",
            },
            {
                title: "플랫폼수수료",
                to: "/settlement/fee",
            }
        ],
    },
    {
        title: "통계",
        subMenus: [
            {
                title: "판매분석",
                to: "/statistics/sales",
            },
            {
                title: "회원분석",
                to: "/statistics/members",
            },
            {
                title: "상품분석",
                to: "/statistics/products",
            },
        ],
    },
    {
        title: "프로모션 및 광고",
        subMenus: [
            {
                title: "프로모션",
                to: "/promotion/promotion",
            },
            {
                title: "광고",
                to: "/promotion/ad",
            },
            {
                title: "쿠폰",
                to: "/promotion/coupon",
            },
        ],
    }
    // {
    //     title: "Order",
    //     subMenus: [
    //         {
    //             title: "Shopping Cart",
    //             to: "/order/shopping-cart",
    //         },
    //         {
    //             title: "Checkout",
    //             to: "/order/checkout",
    //         },
    //         {
    //             title: "History",
    //             to: "/order/history",
    //         },
    //         {
    //             title: "Summaries",
    //             to: "/order/summaries",
    //         },
    //     ],
    // }
]

export default () => {
    return (
        <SideNav menus={menus}></SideNav>
    )
}