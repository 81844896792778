import React, { useState } from "react";
import PagingTable from "components/Table/Paging";
import Card from "components/Card";
import PageTitle from "components/Title/Page";
import { voidPagingTableData } from "components/Table/Paging/helper";
import { columns } from "./config";
import axios from "axios";
import { toast } from "react-toastify";
import _ from "lodash";

const ProductLists = () => {
    const [refreshKey, setRefreshKey] = useState(_.uniqueId());
    const fetchData = async () => {
        try {
            const ret = await axios.get(`https://rest.39f.io/products`);
            console.log("[fetchData] ret", ret);
            return {
                data: ret.data.data,
                page: 1,
                limit: 1
            }
        } catch (e) {
            console.error("[fetchData] error", e);
            return voidPagingTableData;
        }
    }

    const onCreate = async (data) => {
        console.log("[onCreate] callback", data);
        try {
            const ret = await axios.post(`https://rest.39f.io/products`, data);
            console.log("[onCreate] ret", ret);
            toast.success('product saved successfully');
            setRefreshKey(_.uniqueId());
        } catch (e) {
            console.error("[onCreate] error", e);
            toast.error('Failed to save product');
        }
        return true;
    }

    const onEdit = async (data) => {
        console.log("[onEdit] callback", data);
        try {
            if (_.isNil(data.productId)) {
                throw new Error('productId is required');
            }
            if(_.isNil(data.price)) {
                throw new Error('price is required');
            }
            // stockQuantity
            if(_.isNil(data.stockQuantity)) {
                throw new Error('stockQuantity is required');
            }
            // price to number
            data.price = Number(data.price);
            // stockQuantity to number
            data.stockQuantity = Number(data.stockQuantity);
            const ret = await axios.put(`https://rest.39f.io/products/${data.productId}`, _.omit(data, ['productId']));
            console.log("[onEdit] ret", ret);
            toast.success('product updated successfully');
            setRefreshKey(_.uniqueId());
        } catch (e) {
            console.error("[onEdit] error", e);
            toast.error('Failed to update product');
        }
        return true;
    }

    const onDelete = async (data) => {
        console.log("[onDelete] callback", data);
        const ok = window.confirm('Are you sure to delete this product?');
        if (!ok) {
            return;
        }
        try {
            const ret = await axios.delete(`https://rest.39f.io/products/${data.productId}`);
            console.log("[onDelete] ret", ret);
            toast.success('product deleted successfully');
            setRefreshKey(_.uniqueId());
        } catch (e) {
            console.error("[onDelete] error", e);
            toast.error('Failed to delete product');
        }
        return true;
    }

    return (
        <Card>
            <div className="p-3">
                <PageTitle>상품목록</PageTitle>
                <PagingTable
                    refreshKey={refreshKey}
                    fetchData={fetchData}
                    columns={columns}
                    isCrudable={true}
                    onCreate={onCreate}
                    onEdit={onEdit}
                    onDelete={onDelete}
                />
            </div>
        </Card>
    )
}

export default ProductLists;