import { Column } from "components/Table/Simple";
import { formatDateTime } from "components/Table/Simple/presets";

export const columns: Column[] = [
    {
        field: "sellerId",
        label: "판매자ID",
        visible: true,
        creatable: false,
        editable: false,
    },
    {
        field: "name",
        label: "이름",
        visible: true,
        creatable: true,
        editable: true,
    },
    {
        field: "description",
        label: "설명",
        visible: true,
        creatable: true,
        editable: true,
    },
    {
        field: "businessLicense",
        label: "사업자등록번호",
        visible: true,
        creatable: true,
        editable: true,
    },
    {
        field: "status",
        label: "상태",
        visible: true,
        creatable: true,
        editable: true,
    },
    {
        field: "logoUrl",
        label: "로고주소",
        visible: true,
        creatable: true,
        editable: true,
    },
    {
        field: "managers",
        label: "직원목록",
        visible: false,
        creatable: false,
        editable: false,
    },
    {
        field: "createdAt",
        label: "생성일",
        visible: true,
        render: formatDateTime
    },
    {
        field: "updatedAt",
        label: "수정일",
        visible: true,
        render: formatDateTime
    }
]